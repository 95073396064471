
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import Filters from '@/components/Filters.vue';
import Editable from '@/components/Editable.vue';
import Countries from '@/components/Countries.vue';
import Infringements from '@/components/Infringements.vue';

export default defineComponent({
    components: {
        Infringements, Countries, Filters, Editable,
    },
    mixins: [Base],
    mounted() {
        this.getItems();
    },
    data() {
        return {
            selected: [],
            isAdvancedFiltersOpened: false,
            items: {
                data: [] as Array<IObject>,
                meta: {
                    total: 0,
                },
            },
            columns: [
                {
                    visible: false,
                    label: 'Jobs planned',
                    field: 'job_planned',
                    position: 'centered',
                    editable: true,
                },
            ],
            params: {
                page: 1,
                filter: '',
                sortBy: 'created_at',
                status: undefined as any,
                clients: [] as Array<any>,
                orderBy: 'desc',
                active: true,
                country: undefined,
                products: [],
                infringements: [],
                tags: [],
                users: [],
                recent_updates: null,
                planned_jobs: null,
                conformity: null,
            },
        };
    },
    methods: {
        getItems(page = 1) {
            this.params.page = page;
            this.get('objects', {
                params: {
                    ...this.params,
                    country_id: this.params.country ? this.params.country.id : null,
                    clients: this.params.clients.map((client) => client.id),
                    products: this.params.products.map((product) => product.id),
                    users: this.params.users.map((user) => user.id),
                    tags: this.params.tags.map((tag) => tag.id),
                    infringements: this.params.infringements.map((infringement) => infringement.id),
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },
    },
});
